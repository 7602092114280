import * as React from 'react';

import { Text, BlogImage, BlogInfographics } from '../../../components';

import { PageNew, SectionBlog } from '../../../fragments';

import infographic from '../../../images/educational-corner/blog7/infografika.png';
import speechLanguageCommunication from '../../../images/educational-corner/blog7/Govor-jezik-komunikacija.png';
import speechEncouragementTalking from '../../../images/educational-corner/blog7/Poticanje-komunikacije-kod-djece-koja-govore.png';
import speechEncouragementBeforeFirstWord from '../../../images/educational-corner/blog7/Poticanje-komunikacije-prije-djetetove-prve-rijeci.png';

const EncouragingSpeechExpressions = () => {
  return (
    <PageNew
      title="KOKOLINGO – Poticanje Govornog Izražavanja Djeteta"
      description="U ranoj dobi važno je pratiti djetetov rast i razvoj. Roditelji najčešće
      kod djece prvo primijete nepravilan izgovor glasova, odnosno riječi."
      headline="Poticanje jezično-govornog izražavanja u obiteljskom okružju"
    >
      <Text as="p">
        U ranoj dobi važno je pratiti djetetov rast i razvoj. Roditelji najčešće
        kod djece prvo primijete nepravilan izgovor glasova, odnosno riječi.
        Međutim, kao što možemo vidjeti iz prikaza kuće, govor, odnosno izgovor
        riječi dolazi tek na kraju. Da bismo mogli staviti krov, kuća mora imati
        čvrste zidove i čvrste temelje. Tako je i s govorom. Temelji se polažu
        komunikacijom, a zidovi se grade uporabom jezika.
      </Text>
      <BlogImage
        imgSrc={speechLanguageCommunication}
        imgAlt="Govor, jezik, komunikacija"
        imgTitle="Govor, jezik, komunikacija"
      />
      <Text as="h2">Temelji jezično-govornog razvoja</Text>
      <Text as="p">
        Da bi se dijete moglo koristiti govorom, mora shvaćati da govor služi
        kako bismo komunicirali s drugima. Komunikacija se razvija mnogo prije
        nego što dijete progovori prvu riječ. Vidljiva je u djetetovim ranim
        reakcijama, kao što su smijanje u igrama skrivača, rane vokalizacije u
        igri s roditeljima, pokazivanje predmeta koje želi - primjerice igračke
        ili hrane, dozivanje roditelja i drugih ukućana, donošenje i pokazivanje
        predmeta koji mu se sviđaju te igranje igara s odraslom osobom ili s
        drugim djetetom.
        <b>
          {' '}
          Ako dijete komunicira s okolinom na bilo koji način i razumije da
          komuniciranjem može dobiti što želi, ubrzo će početi razvijati svoj
          jezik i govor kako bi komunikacija s osobama iz njegove okoline bila
          što uspješnija.
        </b>
      </Text>
      <Text as="h2">Poticanje komunikacije prije djetetove prve riječi</Text>
      <Text as="p">
        <b>Djeca uče promatrajući osobe u svojoj neposrednoj okolini. </b>
        Tijekom svakodnevnih rutina, kao što su kupanje, presvlačenje i
        hranjenje, roditelji i drugi članovi obitelji često “razgovaraju s
        djecom”. Iako znamo da bebe ne mogu govoriti, one ipak mogu
        komunicirati. Obraćanjem djetetu tijekom svakodnevnih aktivnosti ono uči
        kako se komunikacija odvija. Ako mu pričamo, dijete će mirno slušati.
        Kada napravimo kratku stanku, dijete će reagirati, smiješkom ili
        pomicanjem ruku i nogu. Upravo te izmjene “slušam i čekam” te “smijem se
        i vraćam” prve su komunikacijske vještine. Njima djeca shvaćaju što je
        svrha jezika i govora - slušamo druge, a zatim uzvraćamo, odnosno
        govorimo.
      </Text>
      <Text as="h2">Reagirajte na djetetove pokušaje komunikacije</Text>
      <Text as="p">
        Djeca će se i prije nego što progovore prvu riječ početi koristiti
        glasovima, slogovima i sličnim zvukovima ili u igri ili usmjerenima
        prema drugoj osobi.
        <b>
          {' '}
          Kada dijete počinje vokalizirati, dajte mu do znanja da ste uočili da
          pokušava govoriti.{' '}
        </b>
        Čak i ako to glasanje nije bilo usmjereno vama, nego spontano, u igri,
        odgovorite mu, pohvalite ga, a zatim pričekajte da ponovi. Tako dijete
        vježba upotrebu svojih govornih organa te uči da svojim glasanjem može
        privući pozornost drugih i ostvariti komunikaciju.
      </Text>
      <Text as="h2">
        Potaknite dijete da se koristi gestama - koristite se gestama u vlastitu
        izražavanju
      </Text>
      <Text as="p">
        Geste su pokreti našeg tijela, uglavnom ruku, koje vrlo često prate naš
        govor. One služe kako bismo pojasnili drugima što želimo reći ili kako
        bismo se lakše izrazili.
        <b>
          {' '}
          Budući da su geste motorički i vizualno jednostavnije, djeca se njima
          počnu koristiti prije nego što ovladaju govorom,{' '}
        </b>
        primjerice pokazivanje prstom (pokazivanje nečega), pružanje ruke u zrak
        (želim da me digneš, želim nešto) i mahanje. Koristite se gestom
        pokazivanja kada pitate dijete želi li nešto, kada mu pokazujete
        predmete iz okoline. Koristite se ispruženom rukom (dlan okrenut prema
        gore) kada želite da vam dijete nešto da. Ako uočite da je dijete
        razvilo svoju gestu (primjerice pokrete koji označavaju da želi piti,
        jesti, ljuljati se i sl.), reagirajte na njih, odnosno izgovorite ono
        što je ta gesta trebala značiti. To će pospješiti razumijevanje kod
        djeteta, ali i potaknuti izražavanje, s obzirom na to da su geste često
        odskočna daska prema riječima.
      </Text>
      <Text as="h2">
        Stvarajte prilike za komunikaciju - učinite predmete nedostupnima
      </Text>
      <Text as="p">
        Ako dijete može samostalno doći do igračke koju želi ili može samostalno
        uzeti sok, vjerojatno će to samostalno i napraviti. Želimo da djeca budu
        samostalna, no pritom trebamo imati na umu da je za komunikaciju
        potrebno dvoje.
        <b>
          {' '}
          Ako dijete ne treba vašu pomoć da nešto uzme ili ako mu dajete što
          treba, a da to ne mora od vas tražiti (bilo pokazivanjem, glasanjem
          ili govorom), vrlo je vjerojatno da se neće izražavati jer mu to nije
          potrebno.{' '}
        </b>
        Stavljanjem predmeta na visoko mjesto ili na mjesto koje djetetu nije
        dostupno stvarate priliku u kojoj vas dijete mora uključiti; mora vam
        pokazati ili reći što želi. U takvim prilikama potičete dijete na
        komunikaciju, ali i na jezično-govorno izražavanje.
      </Text>
      <Text as="h2">Igrajte igre u kojima je potrebno dvoje</Text>
      <Text as="p">
        <b>
          Prve djetetove igre kao što su igra skrivača i dodavanje loptom igre
          su kroz koje djeca uče komunikacijske izmjene.{' '}
        </b>
        To su oblici igara u kojima je potrebna interakcija između najmanje
        dvoje ljudi. Komunikacijske izmjene znače da je dijete u jednom trenutku
        osoba koja daje poticaj - baca loptu ili se skriva, i čeka reakciju
        druge osobe, a u drugom trenutku preuzima ulogu osobe koja treba
        reagirati na poticaj - loviti loptu ili tražiti. Opisivanje radnji u tim
        situacijama dodatno obogaćuje komunikaciju, a ujedno i pospješuje
        jezično razumijevanje te potiče dijete na prve pokušaje izražavanja,
        bilo da je to samo ponavljanjem određenih glasova ili prvih riječi.
      </Text>
      <BlogImage
        imgSrc={speechEncouragementTalking}
        imgAlt="Roditelji poticu komunikaciju kod djece koja vec govore"
        imgTitle="Poticanje komunikacije kod djece koja govore"
      />
      <Text as="h2">Poticanje komunikacije kod djece koja govore</Text>
      <Text as="p">
        S razvojem djeca osim komunikacijskih izmjena uče i druge komunikacijske
        vještine. Uče kako prikladno započeti razgovor te kako ga završiti. Uče
        da nije pristojno upadati u riječ, nego da moraju pričekati. Uče da
        sugovornika moraju gledati u oči i pričati mu tako da ih druga osoba
        razumije.
        <b> Neke od tih vještina djecu učimo izravnim putem </b>
        (primjerice kad im kažemo da je pristojno pozdraviti),
        <b> a neke neizravnim putem </b>
        (u svakodnevnim razgovorima s roditeljima, odgojiteljima, prijateljima i
        drugim članovima obitelji). Djeca predškolske dobi, jednako kao i bebe,
        uče komunicirati kroz igru, no kod njih je igra složenija. Ovo su
        primjeri igara koje djeca mogu igrati, a prilika su za uvježbavanje
        komunikacijskih i jezično-govornih vještina.
      </Text>
      <Text as="h2">Igre uloga</Text>
      <Text as="p">
        <b>
          Igre uloga igre su u kojima se djeca prave da su neka druga osoba ili
          da njihove igračke predstavljaju nešto drugo.{' '}
        </b>
        To, primjerice, znači da mogu slagati kocke praveći se da grade kuću,
        igraju se s igračkama u kuhinji praveći se da kuhaju ili se igraju
        trgovine. Ako dijete voli takve igre, pridružite mu se. Takve igre
        idealna su prilika da dijete uvježbava različite načine izražavanja. Kao
        što mi ne razgovaramo jednako na poslu, u trgovini ili u restoranu, tako
        i dijete kroz ovu vrstu igre uči kako komunicirati u različitim
        situacijama. U ovim igrama dijete uči:
        <ul>
          <li>da mora pozdraviti na početku i na kraju</li>
          <li>
            kako se držati teme: npr. ako se igrate kuhanja da ne priča o
            kamionima nego o hrani
          </li>
          <li>
            kako postavljati različite oblike pitanja:
            <ul>
              <li>
                pitanja s upitnom riječi (tko, što, gdje, kamo): Što biste
                htjeli jesti? Što želite kupiti? Kamo ćemo staviti zelenu kocku?
                Koliko trebate jabuka?
              </li>
              <li>
                pitanja u obliku molbe: Možeš li mi dodati plavu kocku? Možeš li
                mi dodati tanjur?
              </li>
              <li>
                pitanja izbora (ILI -ILI pitanja): Želiš li kolač ili juhu?
              </li>
            </ul>
          </li>
          <li>
            kako dati drugima jednostavne i složenije upute: Uzmi žlicu. Daj
            bananu. Dodaj mi plavu kocku. Idi u sobu i donesi još kocaka.
          </li>
        </ul>
        Osim komunikacijskih vještina, u ovim igrama također se usvajaju i novi
        pojmovi i novi rečenični oblici, što obogaćuje djetetov rječnik i
        jezično izražavanje.
      </Text>
      <Text as="h2">Igre pogađanja</Text>
      <Text as="p">
        Igre u kojima jedna osoba mora opisivati predmet, a druga pogoditi o
        kojem je predmetu riječ oblici su igara u kojima
        <b>
          {' '}
          dijete uvježbava još jednu komunikacijsku vještinu, a to je
          postavljanje u poziciju druge osobe.{' '}
        </b>
        Ova je vještina važna jer je djeci potrebna u sklapanju i održavanju
        prijateljstva. Ako dijete ima sliku predmeta ili životinje, drži predmet
        ili igračku, a vi morate pogoditi što je to, dijete se u toj naizgled
        jednostavnoj igri mora koristiti različitim znanjima:
        <ul>
          <li>
            mora razumjeti da to što ono vidi i to što vi vidite nije isto
          </li>
          <li>
            mora znati opisati predmet, što zahtijeva poznavanje određenog broja
            riječi i korištenje jednostavnim i složenim rečenica
          </li>
          <li>
            mora znati prilagoditi opis tako da nije previše očit, a da ga ipak
            razumijete.{' '}
          </li>
        </ul>
        U situaciju kada dijete pogađa, također aktivno uvježbava svoje
        jezično-govorne vještine, odnosno uvježbava jezično razumijevanje.
      </Text>
      <Text as="h2">Prve društvene igre</Text>
      <Text as="p">
        Jednostavne društvene igre, kao što su memori ili Čovječe ne ljuti se,
        igre su u kojima dijete uči pravila koja, osim u igri, može upotrijebiti
        u svakodnevnoj komunikaciji.
      </Text>
      <Text as="p">
        Ponajprije, <b>uvježbava komunikacijske izmjene.</b>
      </Text>
      <Text as="p">
        Tijekom igre u jednom je trenutku dijete na potezu, odnosno baca kocku
        ili otvara karticu, a u drugom čeka ostale igrače. Slično se događa i u
        razgovoru; u jednom je trenutku dijete to koje je na potezu (ono koje
        govori), a u drugom čeka, odnosno sluša što druga osoba govori.
      </Text>
      <Text as="p">
        <b>Uči da mora čekati na red.</b>
      </Text>
      <Text as="p">
        Djeca su često nestrpljiva i žele što prije nešto učiniti ili nešto
        reći. Čekanje na red prisutno je u igrama, ali i u razgovoru.
        Uvježbavanjem ove vještine dijete uči da ne smije prekidati druge te uči
        čekati i slušati čak i ako mu ponekad tema o kojoj se razgovara nije
        zanimljiva.
      </Text>
      <Text as="p">
        <b>Uči o emocijama.</b>
      </Text>
      <Text as="p">
        Ponekad u igri pobjeđujemo, a ponekad gubimo. Ako dijete uči da je u
        redu i jedno i drugo te da se i jedno i drugo događa, lakše će usmjeriti
        emocije. Ako vas tješi kada izgubite, to znači da razumije vašu
        perspektivu i da će vrlo vjerojatno te vještine iskoristiti i u drugim
        situacijama, primjerice u vrtiću će tješiti prijatelja ako padne. Ujedno
        će naučiti kako izraziti da je sretno, a kako da je tužno, a da to bude
        primjereno situaciji.
      </Text>
      <BlogImage
        imgSrc={speechEncouragementBeforeFirstWord}
        imgAlt="Roditelji poticu komunikaciju kod djece koja jos ne govore"
        imgTitle="Poticanje komunikacije kod djece koja jos ne govore"
      />
      <Text as="h2">Savjet za kraj</Text>
      <Text as="p">
        Navedeni su samo neki primjeri dječjih igara koje su poticajne za razvoj
        komunikacije, jezika i govora. Važno je napomenuti da sve dječje igre
        mogu biti prilika za poticanje ovih vještina, ako su u njih uključene
        dvije ili više osoba. Dijete neće imati priliku za komunikaciju, a time
        ujedno ni uvježbavanje jezično-govornih vještina tijekom gledanja crtića
        ili samostalne igre.
      </Text>
      <Text as="p">
        Usvajanje komunikacije te jezično-govornih vještina kod većine djece
        odvija se spontano, kroz svakodnevne interakcije i spontane dječje igre.
        Stoga, roditelji, uključite se u spontane djetetove igre. Reagirajte ako
        vam dijete želi nešto reći ili pokazati. Obogaćujte djetetove igre
        opisujući što radite i smišljajte nove ideje kako proširiti igru. Svaka
        je igra prilika za usvajanje novih vještina ako je u nju uključeno
        dvoje.
      </Text>
      <Text as="p">Maja Grden, mag. logoped.</Text>
      <BlogInfographics infographicSrc={infographic} />
      <SectionBlog />
    </PageNew>
  );
};

export default EncouragingSpeechExpressions;
